<template>
  <moe-page title="抽奖记录">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">抽奖记录</div>
          </div>
        </div>
      </template>

      <moe-inquire @search="luckyGridSearch">
        <el-form-item label="用户编号">
          <el-input :value="luckyGridParams.userId" @input="(value) => luckyGridParams.userId = $moe_formatter.formatterInteger(value)" placeholder="输入用户编号" maxlength="100" clearable />
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model.trim="luckyGridParams.userName" placeholder="输入昵称" maxlength="100" clearable />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model.trim="luckyGridParams.userPhone" placeholder="输入手机号" maxlength="100" clearable />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="luckyGridTableRef" url="/shop/lottery/recordList" :params="luckyGridParams" :numberShow="false" :mode="lotteryId?true:false">
        <el-table-column label="用户编号" prop="userId" min-width="80" />
        <el-table-column label="用户手机号" prop="userPhone" min-width="150" />
        <el-table-column label="用户昵称" prop="userName" min-width="150" />
        <el-table-column label="奖品类型" prop="prizeType" min-width="150" :formatter="formatterPrizeType" />
        <el-table-column label="奖品名称" prop="prizeName" min-width="150" />
        <el-table-column label="中奖时间" prop="createTime" min-width="150" />
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'LotteryManageLuckyGridRecord',
  computed: {
    lotteryId() {
      return this.$route.query.id || ''
    },
  },
  data() {
    return {
      luckyGridParams: {
        pageNum: 1,
        pageSize: 10,
        lotteryId: this.lotteryId,
        userName: '',
        userPhone: '',
        userId: '',
      }
    }
  },
  methods: {
    formatterPrizeType({ prizeType }) {
      return this.$moe_format.getPrizeTypeList(prizeType);
    },
    luckyGridSearch(isSearch) {
      if (!isSearch) {
        this.luckyGridParams = {
          pageNum: 1,
          pageSize: 10,
          lotteryId: this.lotteryId,
          userName: '',
          userPhone: '',
          userId: '',
        }
      }

      this.$refs['luckyGridTableRef'].searchData();
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.luckyGridParams.lotteryId = this.$route.query.id;
    }
  }
}
</script>